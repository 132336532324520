@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(248, 246, 253);
}

body,
input,
select,
button {
  font-family: "Inter", sans-serif;
}

.app {
  width: 500px;
  margin: 0px auto;
  height: auto;
  padding: 20px;
  border: 1px solid darkblue;
}

/* Contact.js */
.form {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px;
}

.form > h1 {
  margin-bottom: 20px;
}

.form > input,
select {
  padding: 15px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 15px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
select:focus {
  border: 1px solid rgb(0, 0, 196);
}

.form > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.form > button {
  padding: 20px;
  border: none;
  background-color: rgb(2, 2, 110);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(0, 0, 196);
}

.success_heading{
  color: #1bc21b;
  margin: 10px;
}

.success_message{
  margin-bottom: 20px;
  font-size: 18px;
  color: #1cbe1c;
}

.success_div{
  display: flex;
  margin: 50px;
  flex-direction: column;
  align-items: center;
}

.pd-top{
  padding-top: 35px;
}

.btn_color{
  background: #1ea59a !important;
}
.err_msg{
  font-size: 10px;
  color: red;
  margin-left: 5px;
  margin-bottom: 0px !important;
}
.err_div{
  height: 20px;
  width: auto;
}
.mg-null{
  margin-bottom: 0px !important;
}
.mb_c{
  margin-bottom: 25px;
}
.mt_ld_c{
  margin-top: 25px !important;
}
@media (min-width: 1200px){
  .custom-form{
    margin-left: 20px !important;
  }
  .heading_center{
    margin-left: 10px;
  }
  .width_60{
    width: 60% !important;
  }
  .width_40{
    width: 40% !important;
  }
  .heading{
    color: #339b90;
    font-family: none;
    font-weight: 600;
    margin: 0px;
  }
  .sub_heading{
    font-weight: 500;
    font-size: 25px;
  }
  .img_div{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
  .data{
    margin-right: 30px;
    font-size: 18px;
  }
  .w-h-third_img{
    width: 100px !important;
    height: 160px !important;
    margin-left: 25px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .img_flex{
    width: 65px !important;
    height: 150px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-left: 35px !important;
  }
  .font_xs{
    font-size: x-small !important;
  }
  .display{
    display: block !important;
  }
  .mt_c_img {
    margin-top: 0.5rem !important;
  }
  .mt_c{
    margin-top: 2rem !important;
  }
}

@media (min-width: 1600px){
  .display{
    display: block !important;
  }
  .container{
    max-width: 1540px !important;
  }
  .data{
    margin-right: 30px;
    font-size: 20px;
  }
  .img_flex{
    width: 120px !important;
    height: 160px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-left: 35px !important;
  }
  .font_xs{
    font-size: 17px !important;
  }
  .w-h-third_img{
    width: 200px !important;
    height: 160px !important;
    margin-left: 25px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  .mt_c{
    margin-top: 2rem !important;
  }
}
@media (min-width: 1800px){ 
  .mt_c{
    margin-top: 2rem !important;
  }
  .container{
    max-width: 1740px !important;
  }
  .display{
    display: block;
  }
  .mt_c_img {
    margin-top: 0rem !important;
  }
  .w-h-third_img{
    width: 200px !important;
    height: 160px !important;
    margin-left: 25px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
}
@media (min-width: 2000px){
  .container{
    max-width: 1940px !important;
  }
  .display{
    display: block;
  }
  .data{
    margin-right: 30px;
    font-size: 25px;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  .mt_c{
    margin-top: 2rem !important;
  }
}
@media (min-width: 2200px){
  .container{
    max-width: 2100px !important;
  }
  .display{
    display: block !important;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  .mt_c{
    margin-top: 2rem !important;
  }
  
}
@media (min-width: 2500px){
  .container{
    max-width: 2440px !important;
  }
  .mt_c_img {
    margin-top: 0.5rem;
  }
  .display{
    display: block !important;
  }
  .data{
    margin-right: 30px;
    font-size: 28px;
  }
  .mt_c{
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px){
  .container{
    max-width: 950px;
  }
  .display{
    display: block !important;
  }
  .img_div{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
  .mb_auto_c {
      margin-bottom: auto !important;
  }
  .pt_c{
    padding-top: 1.5rem!important;
  }
  .mt_c {
    margin-top: 1rem !important ;
  }
  .heading{
    color: #339b90;
    font-family: none;
    font-weight: 600;
    margin: 0px;
  }
  .sub_heading{
    font-weight: 500;
  }
  .img_c{
    margin-top: 8rem !important;
  }
  .img_flex{
    width: 50px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
  }
  .font_xs{
    font-size: xx-small;
  }
  .w-h-third_img{
    width: 185px ;
    height: 150px ;
    margin-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  }
@media (min-width: 500px){
  .container{
    max-width: 480px ;
  }
  .heading{
    color: #339b90;
    font-family: none;
    font-weight: 600;
    margin: 0px;
  }
  .mt_c {
    margin-top: 1.5rem !important;
  }
  .mt_c_img {
    margin-top: 0.5rem;
  }
  .sub_heading{
    font-weight: 500;
  }
  .img_flex{
    width: 120px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
  }
  .font_xs{
    font-size: x-small;
  }
  .w-h-third_img{
    width: 160px ;
    height: 150px;
    margin-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
  }
  .img_div{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
}
@media (min-width: 350px){
  .container{
    max-width: 320px;
  }
  .heading{
    color: #339b90;
    font-family: none;
    font-weight: 600;
    margin: 0px;
  }
  .mt_c {
    margin-top: 1.5rem !important ;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  .sub_heading{
    font-weight: 500;
  }
  .img_flex{
    width: 120px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
  }
  .font_xs{
    font-size: x-small;
  }
  .w-h-third_img{
    width: 160px ;
    height: 150px;
    margin-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
  }
  .img_div{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
}
@media (min-width: 250px){
  .heading{
    color: #339b90;
    font-family: none;
    font-weight: 600;
    margin: 0px;
  }
  .mt_c {
    margin-top: 1.5rem !important ;
  }
  .mt_c_img {
    margin-top: 0rem;
  }
  .sub_heading{
    font-weight: 500;
  }
  .img_flex{
    width: 120px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
  }
  .font_xs{
    font-size: x-small;
  }
  .w-h-third_img{
    width: 160px ;
    height: 150px;
    margin-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
  }
  .img_div{
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
  }
}
